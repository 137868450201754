@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

.inner { overflow-y: auto; overflow-x: hidden; scroll-behavior: smooth; }
.single > div { flex: 1 0 100%; }
.destroy {
  .inner { overflow-x: auto; }
}
.arrow { width: 24px; height: 44px; font-size: 0; z-index: 5; border-radius: 13px; border: 2px solid $theme-color; cursor: pointer;
  &::before               { content: ""; margin: auto; width: 100%; height: 100%; display: block; background: url(../../../assets/images/icon/right-theme-arrow.svg) no-repeat center center / 100% auto; }
  &.prev::before    { @include transform(rotate(180deg)); }
  &:active { background: $theme-color; 
      &::before { @include filter(brightness(0) invert(1)); }
  }
  // &.disabled { display: none !important;}
  .navTransparent & { background-color: var(--light-color-medium); border: none; 
    &:hover::before { @include filter(none); }
    [data-mode=dark] &::before { @include filter(brightness(0) invert(1)); }
  }
}
.dots { margin: 0px -1.5px;
  button { margin: 0px 1.5px; height: 4px; font-size: 0; background: $font-light;
      &::before { content: ""; position: absolute; left: 0; top: 0; width: 0%; height: 100%; background: $theme-medium; opacity: 0; }
      &.active::before  { opacity: 1; width: 100%; @include transition(width 4s linear);  }
  }
}

@media (max-width: 575px) {
  .arrow { width: 20px; height: 38px; 
    &::before               { background-size: 110% auto; }
  }
}